import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {Container, ContentWithPaddingXl} from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import {SectionHeading} from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10 text-center`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800 text-right`}
  p {
    ${tw`mt-2 leading-loose text-right`}
  }

  h1 {
    ${tw`text-3xl font-bold mt-10  text-right`}
  }

  h2 {
    ${tw`text-2xl font-bold mt-8 text-right`}
  }

  h3 {
    ${tw`text-xl font-bold mt-6 text-right`}
  }

  ul {
    ${tw`list-disc list-inside text-right`}
    li {
      ${tw`ml-2 mb-3 text-right`}
      p {
        ${tw`mt-0 inline leading-normal text-right`}
      }
    }
  }
`;

export default ({headingText = "תנאי שימוש באפליקצית Route-it"}) => {
    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <ContentWithPaddingXl>
                    <HeadingRow>
                        <Heading>{headingText}</Heading>
                    </HeadingRow>
                    <Text>

                        <p>אנא קרא בעיון תנאים אלו לפני השימוש בשירותים השונים המוצעים על ידינו</p>

                        <h1>כללי</h1>
                        <p>
                            .ברוכים הבאים לאפליקציה Route-it (להלן האפליקציה) -
                        </p>
                        <p>
                            אפליקציה בבעלותו הבלעדית של בנצי לביאב (להלן: “הבעלים”) -
                        </p>
                        <p>
                            בהנחה ואינך מסכים לאחד מהתנאים המפורטים בתקנון תנאי שימוש זה, הימנע משימוש באפליקציה -
                        </p>
                        <p>
                            הבעלים שומרים לעצמם את הזכות לשנות תנאי שימוש אלו והנך מחוייב לאשר תקנון זה בכל התחברות
                            לאפליקציה -
                        </p>
                        <p>
                            הנך מאשר כי אתה מבוגר וכי אתה רשאי מבחינה משפטית לבוא בהסכם זה ולהשתמש בשירות תחת שמך. חל
                            איסור מוחלט לשימוש באפליקציה מתחת לגיל 18 – כל שימוש כזה שיעשה יהיה באחריות המשתמש בלבד
                        </p>
                        <p>
                            האפליקציה נועדה לשימושך, בכפוף לתנאי שימוש אלו -
                        </p>
                        <p>
                            תנאי שימוש אלו חלים על האפליקציה ו/או חלקים ממנה ועל השימוש בה ובתכנים הכלולים בה, לרבות על
                            עיצובים, קוד מקור, מודולי תוכנה ותכנים ו/או מוצרים ו/או שירותים כלשהם של האפליקציה ו/או
                            שהאפליקציה מאפשרת נגישות ו/או הורדה שלהם, והם מהווים חלק בלתי נפרד מהם ומתפקוד האפליקציה
                            ו/או משירותים המוצעים בה ו/או באמצעותה, בתמורה או שלא בתמורה (להלן, ביחד ולחוד: "השירותים")

                        </p>
                        <p>
                            הסדרת רישיונות כלשהם לצורך שימוש באפליקציה )כגון רישיון נהיגה, רישיון עסק וכו' (כמו גם ביטוח
                            מכל סוג, תשלומי מיסים והוצאות והסדרה של עניינים אחרים הנובעים מהוראות החוק לצורך ביצוע
                            פעילות עסקית או אחרת באמצעות אפליקציה זו, הנם באחריותך בלבד
                        </p>
                        <p>
                            השימוש במידע, במוצרים ו/או בשירותים כלשהם באמצעות האפליקציה ובכלל הינם באחריותך הבלעדית
                            ובכפוף להוראות תנאי שימוש אלו -
                        </p>
                        <p>
                            הנך מסכים בזאת במפורש כי הבעלים פטורים לחלוטין מכל אחריות, נזק או אי נוחות, לך או לאחרים,
                            לגבי כל היבט של שימוש באפליקציה/במוצרים/בשירותים באמצעות האפליקציה או שיסופקו לך באמצעותה
                            והנך נושא את כל האחריות לכך על עצמך בלבד ולבצע את כל הבדיקות הדרושות
                        </p>

                        <h1>תנאי שימוש</h1>
                        <p>
                            תנאי שימוש אלו נועדו להבהיר מה הם התנאים החלים על כל מי שעשה, עושה ו/או יעשה שימוש באפליקציה
                        </p>

                        <p>
                            בתנאי שימוש אלו, הביטוי "תוכן" כולל כל שירות, מוצר, קוד מקור, מודול תוכנה, יישום תוכנה, טקסט, תצלום, תמונה, עיצוב, איור, מפה, קטע צליל, קטע וידיאו, גרפיקה, ביטוי, יצירה, ידע או מידע  אחר, בשלמותם ו/או בחלקם, המוצגים באפליקציה זו ו/או שימשו לתכנונה/אפיונה ו/או המוזנים אליה ו/או הכלולים בה ו/או הממוענים אליה/ממנה, וזאת בכל אמצעי תקשורת ו/או קשר, בין בתשלום ובין בלא תשלום, על ידך ו/או צד שלישי כלשהו, וכן כל זכות קניין רוחני, לרבות, אך לא רק, פטנטים, סימני מסחר זכויות יוצרים וזכויות מוסרית, המתייחסות לאלו מהם
                        </p>
                        <p>
                            הפנייה היא לנשים ולגברים כאחד אך מענייני נוחות הפניות כתובות בלשון זכר. כמו-כן, פניה ליחיד משמעה גם לרבים
                        </p>

                        <h1>שידור מיקום</h1>
                        <p>
                            כידוע לך האפליקציה כוללת תכונה של איתור מיקום הנהג (הנהג בלבד, באם אינך נהג אלא משתמש הצופה במסלול, פעולות הקשורות למיקום אינן רלוונטיות עבורך)
                        </p>
                        <p>
                            כל המיקומים ישמשו לצורכי האפליקציה בלבד – בשעות המוגדרות בלבד
                        </p>
                        <p>
                            <strong>                            איסוף מיקום הנהג מתבצע בשעות השידור המוגדרות למסלול בלבד</strong>
                        </p>
                        <p>
                            שעות המסלול יהיו כפופים להחלטתך הבלעדית. הבעלים מעמיד לרשותך את האפשרות לשנות מעת לעת את זמני העבודה בהתאם לזמני המסלול המבוקש (וזאת בפניה כפי שפורט לעיל)
                        </p>
                        <p>
                            <strong>                            בשום שלב לא נאסף מידע שלא לצרכי האפליקציה. גם זה באם יאסף משמש אך ורק להצגת מיקום הנהג על מפה עבור כלל המשתמשים</strong>
                        </p>
                        <p>
                        <strong>   בעת שידור המסלול, האפליקציה משדרת את מיקומך גם מאחורי הקלעים וזאת בכדי לאפשר לך שימוש חופשי במכשיר ואף סגירת מסך בעת נסיעה </strong>
                        </p>

                        <p>
                            אי הסכמה לאחד מתנאים אלו מחייב אותך בזאת לפנות לבעלים בכתב בצירוף כל הפרטים הרלוונטים                        </p>

                        <h1>הגישה לאפליקציה</h1>
                        <p>
                            הבעלים שומרים לעצמם את הזכות לאפשר או לא לאפשר שימוש באפליקציה בכל עת, וכן לשנות ו/או להפסיק לעשות כן, והכל לפי שיקול דעתם הבלעדי
                        </p>
                        <p>
                            יכול שהגישה לאפליקציה או חלקה, והאפשרות לבצע פעולות  מסוימות, תתאפשר למשתמשים מסוימים אך תהא מוגבלת למשתמשים אחרים, מסיבות כאלה ואחרות, או לא תתאפשר כלל ו/או תהא כרוכה בהקצאת שם משתמש ו/או סיסמה ו/או תהא מותנית במילוי שאלון ו/או מסירת פרטים אישיים, הכל לפי שיקול דעתם המוחלט והבלעדי של הבעלים
                        </p>
                        <p>
                            הבעלים יוכלו להתחיל לדרוש זאת ללא הודעה מוקדמת, או להוסיף  לדרוש זאת, או להפסיק לדרוש זאת, או לעשות כן מדי פעם בפעם,  ללא הודעה מוקדמת, ולפי שיקול דעתם המוחלט והבלעדי
                        </p>

                        <h1>שימוש במידע שמסרת</h1>
                        <p>
                            אם מסרת כתובת דואל/מספר פלאפון הנך מסכים בזאת לקבל הודעות  לכתובת דואל/מספר פלאפון שמסרת לצרכים שונים. הנך רשאי לבקש להפסיק שיגור של הודעות כאמור בהתאם להוראות כל דין
                        </p>
                        <p>
                            ע"י השימוש באפליקציה הנך מהסכים בזאת לאיסוף והתחקות אחר נתונים, מידע, תוכן, פעולות והרגלי שימוש שלך כלים כגון Google Analytics, Oribi, Mixpanel וכו) ואלו ישמשו לצרכים שונים, אך לא לצרכים שיווקיים
                        </p>

                        <h1>סוגי התכנים באפליקציה</h1>
                        <p>
                            הבעלים שומרים לעצמם את הזכות לבחור האם ואלו תכנים יופיעו ו/או יפורסמו, או יפסיקו/יוסיפו להופיע ו/או להתפרסם באפליקציה,  או יופיעו/יתפרסמו מדי פעם בפעם, הכל לפי שיקול דעתם הבלעדי  והמוחלט, ולפי צרכיהם המסחריים, וללא הודעה מראש או עדכון בדיעבד
                        </p>
                        <p>
                            הבעלים עשויים לשנות, להוסיף, לגרוע, למחוק ולעדכן מעת לעת את תכני האפליקציה ו/או מראה האפליקציה ו/או אופן הפעלתה ו/או אופן השימוש בה ו/או מוצרים ו/או שירותים הכלולים בה, ללא הודעה מוקדמת ולפי שיקול דעתם הבלעדי
                        </p>
                        <p>
                            בתכני האפליקציה עלולות ליפול שגיאות לרבות במועדים, מחירים, תנאי תשלום ועוד, והבעלים שומרים על זכותם לתקן כל טעות כאמור בכל עת
                        </p>

                        <h1>חיפוש תכנים באפליקציה</h1>
                        <p>
                            הבעלים שומרים לעצמם את הזכות להפעיל דרכי גישה, אמצעי חיפוש ו/או מנועי חיפוש באפליקציה, שיאפשרו גישה לתכנים מסוימים באפליקציה או  חלק ממנה עפ"י בחירת הבעלים, וכן לשנות ו/או להפסיק לעשות כן, והכל    לפי שיקול דעתם הבלעדי
                        </p>

                        <h1>זמינות תכנים באפליקציה</h1>
                        <p>
                            הנך מאשר כי יתכנו תקלות כאלה ואחרות, הנובעות מסיבות כאלה ואחרות, העלולות למנוע את הגישה ו/או השימוש באפליקציה, או להכביד עליהם,  לרבות עקב קשיי תקשורת ו/או לשם תחזוקה ו/או מטעמים אחרים, ויכול    שהשימוש באפליקציה ייקטע ו/או יופסק ללא השלמה ו/או שמירה. הבעלים יהיו פטורים מכל אחריות לכך, ועליך לשמור מראש כל מידע שבדעתך לעשות בו שימוש או להזין לאפליקציה, בטרם עשותך כן
                        </p>
                        <p>
                            מבלי לגרוע מן האמור לעיל, הנך מאשר כי ידוע לך כי במקרים מסוימים השימושיות של האפליקציה תלויה בתפקוד תקין של מכשיר הסמארטפון שברשותך ורכיבים הכלולים בו כי GPS ,BlueTooth ועוד. אשר איכותם, אי
                            דיוקם, שיבוש בהם ותקלות, עלולים להפריע לחווית השימוש שלך באפליקציה. הבעלים יהיו פטורים מכל אחריות לכך

                        </p>
                        <p>
                            אין להזין לאפליקציה ו/או להפיץ ממנה ו/או באמצעותה תוכן כלשהו שעלול לפגוע באדם כלשהו, שמו הטוב, פרטיותו, קנייניו או זכויותיו, ואין להזין תכנים לאפליקציה בניגוד להוראות תנאי שימוש אלו ו/או הוראות כל דין
                        </p>
                        <p>
                            הבעלים שומרים לעצמם את הזכות, לערוך, לשנות ואף למחוק תכנים כאלו. הנך לתת בזאת את הסכמתך המפורשת לעריכת שינויי תוכן ו/או עריכה ו/או מחיקה של תכנים כלשהם שהוזנה לאפליקציה
                        </p>
                        <p>
                            זו זכותך לפנות לבעלים ולבקש מחיקה של תכנים שהזנת לאפליקציה והבעלים יעשו כל שביכולתם למלא בקשה זו
                        </p>

                        <p>
                            האחריות לכל מעשה או מחדל שאינם עולים בקנה אחד עם האמור לעיל הינה עליך בלבד
                        </p>

                        <h1>זכויות בעלות וזכויות קניין רוחני</h1>
                        <p>
                            זכויות הקניין הרוחני מוגנות בישראל ובחו"ל מכוח חוקי מדינת ישראל ואמנות בינלאומיות
                        </p>
                        <p>
                            הבעלים מכבדים זכויות בעלות וזכויות קניין רוחני של אחרים, וגם עליך לעשות כן
                        </p>
                        <p>
                            אפליקציה זו ותכניה הם הרכוש הבלעדי של בעלי זכויות הקניין בהם, ואינם רכושך, ואין לעשות בהם כל שימוש אלא בכפוף לתנאי שימוש אלו
                        </p>
                        <p>
                            אלא אם הוסכם במפורש אחרת מראש ובכתב, יש לראות את  המידע ו/או זכויות הקניין הרוחני באפליקציה כשל הבעלים בלבד  ו/או כמותרים לשימוש הבעלים ע"י צדדים שלישיים, ובכלל זה זכויות יוצרים, זכויות מוסריות, סימני מסחר, סימני שרות, שמות מסחריים, מדגמים, פטנטים, יצירות, עיצובים, המצאות, סודות מסחריים, מידע טכנולוגי, מידע פונקציונאלי, מידע מקצועי, מידע מסחרי ועסקי, וכל מידע ו/או זכות קניין רוחני, בין שנרשמה, ובין שלא   נרשמה )להלן: "זכויות הקניין הרוחני"(
                        </p>
                        <p>
                            צדדים שלישיים בעלי זכויות קניין רוחני בתכנים שהוזנו לאפליקציה ללא ידיעת הבעלים של ו/או הסכמתם, מתבקשים לפנות לבעלים ו/או למי מטעמם ולדווח להם על כך
                        </p>
                        <p>
                            הבעלים שומרים לעצמם את הזכות לאכוף, להסיר, לשנות, לתקן,  ולמנוע כל שימוש ו/או הזנה לאפליקציה ו/או פרסום של תכנים המפרים זכויות קניין רוחני שלהם ו/או של צדדים שלישיים, ולדרוש כל פיצוי ו/או שיפוי מכל מי שעלול להעמיד את עצמו ו/או את הבעלים במצב של הפרה אפשרית של זכויותיהם ו/או של אחרים
                        </p>

                        <h1>שימוש בתכני האפליקציה</h1>
                        <p>
                            אין לעשות באפליקציה ו/או בתכניה כל שימוש פוגעני באדם כלשהו, שמו הטוב, פרטיותו, או זכויותיו, ואין לעשות שימוש כלשהו באפליקציה ו/או בתכניה שהינו בניגוד לתנאי שימוש אלו ו/או הוראות כל דין, נוהג, או תקנת הציבור
                        </p>
                        <p>
                            כל שימוש באפליקציה ו/או בתכנים כלשהם הכלולים בה ו/או נלקחו ממנה, הינם לשימוש אישי בלבד, ואין לעשות כל שימוש אחר לרבות עסקי, שיווקי או מסחרי, אלא אם יותר לעשות כן ע"י הבעלים במפורש, מראש ובהיתר בכתב, ובהתאם לתנאי ההיתר, כאמור בגוף האפליקציה
                        </p>
                        <p>
                            בכלל זה, ומבלי לגרוע מכלליות האמור לעיל, אין לנצל איזו מזכויות הקניין הרוחני של הבעלים ו/או צד שלישי ללא הסכמתם, לרבות, אך לא רק, בדרך של גזירה, העתקה, שמירה כקובץ, שכפול, הפצה, עיבוד, מחיקה, תוספת, שינוי, מכירה, השכרה, השאלה, מסירה, הצגה פומבית, ביצוע פומבי, יצירת יצירה ניגזרת, או כל דרך אחרת
                        </p>
                        <p>
                            העושה כן ללא היתר מפורש מאת הבעלים, מראש ובכתב, ככל שיינתן ובכפוף לתנאיו, מסתכן בהעמדה לדין פלילי ובהליכים אזרחיים, לרבות צווי מניעה, חיפוש, תפיסה, תביעות כספיות, עיכוב טובין, מעצר, מאסר, וכל אמצעי אכיפה אחר ו/או נוסף שהבעלים ימצאו לנכון
                        </p>
                        <p>
                            אין ולא יהיה בהיתר שיינתן לך ע"י הבעלים לעשות שימוש בתכנים כלשהם באפליקציה, אם וככל שיינתן, כדי לגרוע מזכויות הבעלים,0  לרבות זכויות בעלות בכלל, וזכויות הקניין הרוחני בפרט, ולא ישתמע ממעשה או מחדל כלשהו של הבעלים משום הסכמה להעברה ו/או למתן שימוש בזכות קניין רוחני כלשהי או זכות אחרת לבעל ההיתר
                            או לאדם אחר, אלא אם הוסכם על כך במפורש, מראש ובכתב,  ובכפוף לזכויות השייכות לבעלים, או שהאחרונים קיבלו מצדדים שלישיים, ובכפוף להוראות כל דין

                        </p>
                        <p>
                            מבלי לגרוע מהאמור לעיל, "שימוש הוגן" ביצירה, כמשמעותו בכל דין הינו מותר למטרת לימוד עצמי, מחקר, ביקורת, סקירה, דיווח עיתונאי, הבאת מובאות, הוראה ובחינה על ידי מוסד חינוך, בכפוף לכל דין בישראל בלבד. בכל שימוש הוגן, חובה לתת קרדיט למחבר התוכן ולאפליקציה. אין להטיל בתוכן כל פגם, או לעשות בו כל סילוף או שינוי צורה אחר, או פעולה פוגענית אחרת, אם יש באילו מהם כדי לפגוע בכבודו או בשמו של היוצר
                        </p>

                        <h1>פטור מאחריות</h1>
                        <p>
                            האפליקציה והתכנים הכלולים בה, מוצגים ומוצעים לשימוש במצבם כפי שהוא )"Is As.)"
                        </p>
                        <p>
                            הבעלים לא יישאו באחריות כלשהי, חוזית ו/או נזיקית ו/או אחרת, לשימוש כלשהו שייעשה באפליקציה ו/או בישומייה, ו/או למניעה ו/או להפרעה משימוש באפליקציה מסיבה כלשהי
                        </p>
                        <p>
                            הבעלים לא יישאו באחריות כלשהי לתכנים שיימסרו או יוזנו על ידך, חוקיותם, אמינותם, מהימנותם, דיוקם, שלמותם, קבצי מחשב שיצורפו אליהם, ולכל נזק, הפסד, עגמת נפש או תוצאות, במישרין או בעקיפין, לך ו/או לצד שלישי כלשהו
                        </p>
                        <p>
                            הבעלים לא יישאו בכל אחריות לכל נזק עקב הורדת האפליקציה ו/או נזק שנבע מקישור הכלול באפליקציה ולכל נזק שנבע מהצגה או מפרסום של תכנים כאמור בכל דרך אחרת. כמו כן, אחריות המלאה והבלעדית לכל קישור, הצגה או פרסום של התכנים שנעשו על ידך  הנם עליך והנך להתחייב בזאת לשפות את הבעלים בגין כל נזק שייגרם כתוצאה מכך
                        </p>
                        <p>
                            הבעלים לא מתחייבים לשמור תכנים כלשהם שהזנת לאפליקציה,  ו/או שהורדת מהאפליקציה, והאחריות על שמירת תכנים באמצעים שלך, הינם בלתי תלויים בבעלים ו/או באפליקציה ו/או במי הקשור עימם, והיא עליך בלבד. שמירת תכנים כאלו הינה בכפוף להוראות כל דין ותנאי שימוש אלו
                        </p>
                        <p>
                            הנך להצהיר בזאת כי כל אחריות וסיכון לכל נזק ו/או הפסד שייגרמו לך בגין השימוש באפליקציה הינם עליך,לרבות: תאונות, עוגמת נפש, היבטים כלכליים וכל נזק אחר. כן יצויין כי השימוש במכשיר הנייד בזמן נסיעה הינו אסור ומסוכן לך ולסביבתך
                        </p>



                        <h1>שיפוי</h1>
                        <p>
                            הנך להתחייב לשפות ולפצות את הבעלים ו/או כל מי מטעמם, בגין כל מעשה ו/או מחדל שגרם לבעלים נזק ישיר או עקיף, הפסד, אבדן-רווח, תשלום או הוצאה, בין עקב הפרת תנאי שימוש אלו, בין עקב הפרת הוראות כל דין, ובין עקב כל טענה או תביעה מצד שלישי כלשהו
                        </p>

                        <h1>סמכות שיפוט</h1>
                        <p>
                            דיני מדינת ישראל בלבד יחולו על הוראות תנאי שימוש אלו ופרשנותם, ומקום הבוררות והשיפוט יהיו בבית המשפט המוסמך במחוז תל אביב או מחוז מרכז בלבד
                        </p>

                        <h1>שינויים בתנאי שימוש</h1>
                        <p>
                            יכול שבהוראות תנאי שימוש אלו יחולו מעת לעת שינויים, עדכונים, תוספות, מחיקות, לפי שיקול דעתם המוחלט והבלעדי של הבעלים, עליהם תפורסם הודעה. אם הינך מעוניין להמשיך לעשות שימוש באפליקציה זו, ייראו אותך
                            כמסכים לתנאים המתוקנים, אלא אם הם יעמדו בסתירה להוראות החוק

                        </p>



                        <h1>   מדיניות פרטיות לאפליקציה</h1>
                        <p>מדיניות פרטיות זו היא חלק בלתי נפרד מתנאי השימוש לאפליקציה "Route-it" להלן: "האפליקציה"( בבעלות בנצי לביאב )להלן: "הבעלים"( וכל שימוש בה על ידך כפוף להוראות תנאי השימוש להלן</p>
                        <p>מטרת מדיניות הפרטיות היא לפרט בפניך, המשתמש/ת, כיצד נאספים עליך נתונים באמצעות האפליקציה, מה מטרת השימוש וכיצד נעשה בהם שימוש, אם בכלל</p>
                        <p>הוראות מסמך זה יפורשו בהתאם לדיני מדינת ישראל בלבד. בכל מקרה של סכסוך, מקומו של הליך כלשהו היה בבית המשפט המוסמך במחוז תל-אביב או מחוז המרכז בלבד, והמותב הרלבנטי ידון בו עפ"י דיני מדינת ישראל בלבד</p>
                        <p> הוראות מדיניות הפרטיות יחולו על האפליקציה יחד עם שינויים, עדכונים, תוספות, או מחיקות, כפי שיבוצעו מדי פעם בפעם על פי שיקול דעתו המוחלט והבלעדי של הבעלים, בהודעה שתפורסם )להלן: "השינויים"(. המשך שימוש באפליקציה לאחר שינויים אלו יחשב כהסכמה לתנאים המתוקנים, אלא אם כן הם סותרים הוראות קוגנטיות (בלתי ניתנות להתניה) של הדין הישראלי</p>
                        <p>   אנו שומרים לעצמנו את הזכות לאפשר גישה לאפליקציה על ידי תהליך רישום או על ידי אמצעי רישום שמעמידים צדדים שלישיים כגון פייסבוק, גוגל וכו</p>
                        <p> בתהליך הרשמה, תשלום, או תהליך הזנה אחר באפליקציה, ייתכן שיהיה עליך להזין פרטים כמו שמך, כתובת הדואר האלקטרוני, כתובת דואר או פרטים אחרים לפי העניין, אותם אנו אוספים</p>
                        <p>אנו עשויים להשתמש במידע שאנו אוספים ממך כאמור בעת רישום, הרשמה לניוזלטר שלנו, בעת עריכת סקר שיווק, או באחד או יותר מהמקרים הבאים:</p>
                        <p>כדי להתאים אישית את חווית המשתמש שלך וכדי לאפשר לנו לספק לך את השירות או המוצר שבו יש לך עניין וכו</p>
                        <p>האפליקציה נבדקת על בסיס קבוע כדי לאתר חורי אבטחה ונקודות תורפה ידועות על מנת להפוך את השימוש בה עבורך בטוח ככל האפשר</p>
                        <p>אנו מיישמים מגוון של אמצעי אבטחה כאשר משתמשים אחרים עושים שימוש באפליקציה כדי להבטיח שלא תהיה להם נגישות אלא למידע שלהם ותוך שמירת המידע האישי שלך מפניהם</p>

                        <h1>צדדים שלישיים</h1>
                        <p>אנחנו לא מוכרים, סוחרים, או מעבירים לגורמים חיצוניים את פרטיך האישיים, למעט אלו שהסכמת מראש</p>
                        <p>אנו נעשה את המירב כדי לא לאפשר לצדדים שלישיים לבצע מעקב אחריך</p>
                        <p>הוראות מסמך זה יפורשו בהתאם לדיני מדינת ישראל בלבד. בכל מקרה של סכסוך, מקומו של הליך כלשהו היה בבית המשפט המוסמך במחוז תל-אביב או מחוז המרכז בלבד, והמותב הרלבנטי ידון בו עפ"י דיני מדינת ישראל בלבד</p>
                        <p> הוראות מדיניות הפרטיות יחולו על האפליקציה יחד עם שינויים, עדכונים, תוספות, או מחיקות, כפי שיבוצעו מדי פעם בפעם על פי שיקול דעתו המוחלט והבלעדי של הבעלים, בהודעה שתפורסם )להלן: "השינויים"(. המשך שימוש באפליקציה לאחר שינויים אלו יחשב כהסכמה לתנאים המתוקנים, אלא אם כן הם סותרים הוראות קוגנטיות (בלתי ניתנות להתניה) של הדין הישראלי</p>


                        <h1>פנה אלינו</h1>
                        <p>   נשתדל לטפל בפניות מתאימות אלינו בהקדם האפשרי. פניות כאמור יש להעביר לפי הפרטים להלן:</p>
                        <p>      שם:</p>
                        <p>   כתובת:</p>
                        <p>   טלפון:</p>
                        <p>   פקס:</p>
                        <p>      :EMAIL</p>

                        <p>בכתובת מייל </p>
                        <p>bentsi.leviav@route-it.io</p>


                        <h1>   בברכת שימוש נעים</h1>
                        <h1>   אפליקציית Route-it</h1>
                    </Text>
                </ContentWithPaddingXl>
            </Container>
            {/*<Footer />*/}
        </AnimationRevealPage>
    );
};
