import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import RightSideFeatureTwoColumns from "components/features/RightSideFeatureTwoColumns.js";
import FAQ from "components/faqs/SingleCol.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import metricsAndGraphImageSrc from "images/routeit/undraw_Investing_re_bov7 (6).svg";
import easyToGetStartedImageSrc from "images/routeit/undraw_Address_re_yaoj.svg";
import ThreeColumnDashedBorderFeatures from "components/features/DashedBorderSixFeatures";
import ThreePlansWithHalfPrimaryBackground from "../components/pricing/ThreePlansWithHalfPrimaryBackground";
import {Element, scroller} from 'react-scroll'
import TwoColContactUsWithIllustrationFullForm from "../components/forms/TwoColContactUsWithIllustrationFullForm";
import MiniCenteredFooter from "../components/footers/MiniCenteredFooter";
export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
        <Element name={"Home"}/>
      <Hero roundedHeaderButton={true} />
        <ThreeColumnDashedBorderFeatures/>
        <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            Route-it  <HighlightedText>MaaS</HighlightedText> Solution
          </>
        }
      />
        <Element name={"Solutions"}/>
        <Element name={"ManagementService"}/>
      <MainFeature
        subheading={<Subheading>IMPROVE EFFICIENCY</Subheading>}
        imageSrc={metricsAndGraphImageSrc}
        // imageBorder={false}
        // imageDecoratorBlob={false}
      />
        {/*For animation to work equally*/}
        <React.Fragment/>
        <Element name={"MobileApp"}/>
        <RightSideFeatureTwoColumns
            subheading={<Subheading>PASSENGERS</Subheading>}
            heading={
                <>
                    Mobile Application for your<HighlightedText> Passengers.</HighlightedText>
                </>
            }
            textOnLeft={false}
            imageSrc={easyToGetStartedImageSrc}
            // imageDecoratorBlob={true}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        />

        <Element name={"pricingPlans"}/>
        {/*For animation to work equally*/}
        <React.Fragment/>
        <ThreePlansWithHalfPrimaryBackground
            description={""}
        />
        {/*For animation to work equally*/}
        <React.Fragment/>
        <Element name={"FAQ"}/>
      <FAQ
        subheading={<Subheading>FAQ</Subheading>}
        description={""}
        heading={
          <>
            Do you have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
            {
                question: "Who can use Route-it Platform?",
                answer:
                    "Both public and private organizations that want to empower their transportation data. On the public sector, Route-it supports municipalities, cites, transportation centers and more. On the private sector, Route-it empower transportation companies, Hi-tech companies and many other private entities."
            },
            {
                question: "How quickly will I benefit from Route-it?",
                answer:
                    "Due to zero integration needs, and as Route-it being a combination of MaaS and SaaS, your benefit is immediate."
            },
            {
                question: "What kind of integration is needed?",
                answer:
                    "Route-it platform is a MaaS solution, therefore, no integration is needed."
            },
          {
            question: "Can I try it before subscribing?",
            answer:
              "Sure. Contact us for a free 2 weeks trial."
          },
          {
            question: "Which payment methods are you accepting?",
            answer:
              "All the major credit cards (excluding debit cards) - Visa, Master Card, American Express, Discover, Diners Club, JCB, Carte Bleue, Union Pay. We offer annual and multi annual billing. Contact us for details."
          },
          {
            question: "What if I want to cancel?",
            answer:
              "You can cancel your account at any time before your next billing period. You won’t be charged for the next period of service."
          }
        ]}
      />
        <Element name={"ContactUs"}/>
        {/*For animation to work equally*/}
        <React.Fragment/>
      <TwoColContactUsWithIllustrationFullForm description={"Interested in learning more about Route-it platform? Our team will love to assist, schedule a demo or suggest the best plan for you."}/>
        <Footer />
    </AnimationRevealPage>
  );
}
